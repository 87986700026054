import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';


@Injectable()
export class ReportsService {

    constructor(private http: HttpClient) { }

    getSalesAndCosts(fromDate: string = 'all'): Observable<any> {
        return this.http.get(environment.apiUrl + '/api/report/v1/sales-and-costs?fromDate=' + fromDate);
    }

    getSalesBreakdown(fromDate?: string, toDate?: string): Observable<any> {
      const params = new HttpParams({
        fromObject: {
          fromDate,
          toDate,
        }
      });
      return this.http.get(environment.apiUrl + '/api/report/v2/sales-breakdown', { params });
    }

    getBestSellerProducts(): Observable<any> {
        return this.http.get(environment.apiUrl + '/api/report/v1/best-seller-products');
    }

    getCheckoutVisits(): Observable<any> {
        return this.http.get(environment.apiUrl + '/api/report/v1/checkout-visits');
    }

    getCogsMargin(): Observable<any> {
        return this.http.get(environment.apiUrl + '/api/report/v1/cogs-margin');
    }

    getSalesCogs(year): Observable<any> {
        const qParams = new HttpParams({
            fromObject: {
                year: year
            }
        });
        return this.http.get(environment.apiUrl + '/api/report/v1/sales-cogs', {params: qParams});
    }

    getPaymentMethods(): Observable<any> {
        return this.http.get(environment.apiUrl + '/api/report/v1/payment-methods');
    }

    getKpis(year): Observable<any> {
        const qParams = new HttpParams({
            fromObject: {
                year: year
            }
        });
        return this.http.get(environment.apiUrl + '/api/report/v1/kpis', {params: qParams});
    }

    getPaymentMethodsTotals(): Observable<any> {
        return this.http.get(environment.apiUrl + '/api/report/v1/payment-methods-totals');
    }
}
