export const ERR_RECORD_NOT_FOUND = 'ERR-RECORD-NOT-FOUND';

export interface DateAt {
    epochSecond: number;
    nano: number;
}

export interface I18n {
    es: string;
    en: string;
}

export interface Description {
    es: string;
    en: string;
    summary?: string;
    hasSummary?: string;
}

interface Currencies {
    mxn: number;
    usd: number;
}

export interface Address {
    id?: string;
    accountId?: string;
    name?: string;
    addressLine1?: string;
    addressLine2?: string;
    addressLine3?: string;
    crossStreet?: string;
    reference?: string;
    city?: string;
    state?: string;
    country?: string;
    primaryPhone?: string;
    secondaryPhone?: string;
    postalCode?: string;
    integrations?: any;
    createdAt?: DateAt,
    updatedAt?: DateAt;
}
