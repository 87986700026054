import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../environments/environment';
import { Article } from './article.interface';

@Injectable()
export class ContentManagerService {

    constructor(private http: HttpClient) { }

    getArticleTypes(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/api/v1/admin/article/types`);
    }

    getArticles(type: string): Observable<Array<Article>> {
        const qParams = new HttpParams({
            fromObject: {
                q: type,
                mustBeActive: 'false'
            }
        });
        return this.http.get<Array<Article>>(`${environment.apiUrl}/api/v1/admin/articles`, {params: qParams});
    }

    getArticleBySlug(slug): Observable<Article> {
        return this.http.get<Article>(`${environment.apiUrl}/api/v1/admin/article/${slug}`);
    }

    updateArticle(article: Article): Observable<Article> {
        delete article['createdAt'];
        delete article['updatedAt'];

        return this.http.put<Article>(`${environment.apiUrl}/api/v1/admin/article`, article);
    }

    updateAllArticles(articles): Observable<any> {
        return this.http.post(`${environment.apiUrl}/api/v1/admin/articles/all`, articles);
    }
}
