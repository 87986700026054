import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../environments/environment';

@Injectable()
export class DomainService {
  constructor(private http: HttpClient) { }


  updateDomain(domain): Observable<any> {
    return this.http.put(`${environment.apiUrl}/api/v1/domain`, domain)
  }

  getAccountSettings(section: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/accounts/settings/${section}`);
  }

  verifyDns(domain): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/accounts/dns?domain=${domain}`)
  }

  updateSettings(section: string, fields): Observable<any> {
    return this.http.put(`${environment.apiUrl}/api/v1/accounts/settings/${section}`, fields)
  }
}
