import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../environments/environment';
import { Address } from '../shared/interfaces/common.interface';
import { Customer } from './customer.interface';


@Injectable()
export class CustomersService {
    constructor(private http: HttpClient) { }

    findAll(q: string, page: number = 0, perPage: number = 0): Observable<any> {
        return this.http.get(`${environment.apiUrl}/api/v1/customers?q=${q}&page=${page}&per_page=${perPage}`);
    }

    findById(id: string): Observable<any> {
        return this.http.get(environment.apiUrl + '/api/v1/customer?id=' + id);
    }

    create(customer: Customer) {
        return this.http.post(environment.apiUrl + '/api/v1/customer', customer);
    }

    update(customer: Customer): Observable<any> {
        delete customer.updatedAt;
        delete customer.createdAt;

        return this.http.put(environment.apiUrl + '/api/v1/customer', customer);
    }

    getAddressesForUser(customerId: string): Observable<any> {
        return this.http.get(environment.apiUrl + '/api/v1/address?customer=' + customerId);
    }

    getLogisticsSettings(): Observable<any> {
        return this.http.get(environment.apiUrl + '/api/v1/logistics/settings');
    }

    updateAddress(address: Address): Observable<any> {
        delete address.updatedAt;
        delete address.createdAt;

        return this.http.put(environment.apiUrl + '/api/v1/address', address);
    }

    getOrdersForUser(customerId: string): Observable<any> {
        return this.http.get(environment.apiUrl + '/api/v1/admin/orders/customer?id=' + customerId);
    }

    getRewardsSummary(customerId: string): Observable<any> {
        return this.http.get(environment.apiUrl + '/api/v1/customer/rewards-summary?customerId=' + customerId);
    }

    getRewardsTxs(customerId: string): Observable<any> {
        const qParams = new HttpParams({
            fromObject: {
                customerId: customerId
            }
        })
        return this.http.get(`${environment.apiUrl}/api/v1/customer/rewards-transactions`, {params: qParams});
    }

    setSpecialDiscount(data): Observable<any> {
        return this.http.post(environment.apiUrl + '/api/v1/customer/special-discount', data);
    }

    removeSpecialDiscount(customerId: string): Observable<any> {
        return this.http.delete(environment.apiUrl + '/api/v1/customer/special-discount?customerId=' + customerId);
    }

    adminCreate(customer): Observable<any> {
        return this.http.post(environment.apiUrl + '/api/v1/customer/create-admin', customer);
    }

    adminUpsert(address): Observable<any> {
        return this.http.post(environment.apiUrl + '/api/v1/admin/address/upsert', address);
    }


}
