import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';


import { Coupon } from './coupon.interface';
import { SpecialDiscount } from './special-discount.interface';

@Injectable()
export class LoyaltyService {

    constructor(private http: HttpClient) { }

    getCoupons(): Observable<any> {
        return this.http.get(environment.apiUrl + '/api/v1/admin/coupons');
    }

    createCoupon(coupon: Coupon): Observable<any> {
        return this.http.post(environment.apiUrl + '/api/v1/admin/coupon', coupon);
    }

    updateCoupon(coupon: Coupon): Observable<any> {
        delete coupon.updatedAt;
        delete coupon.createdAt;
        delete coupon.validAtAsInstant;
        delete coupon.expiresAtAsInstant;

        return this.http.put(environment.apiUrl + '/api/v1/admin/coupon', coupon);
    }

    redeemedCount(code: string): Observable<any> {
        return this.http.get(environment.apiUrl + `/api/v1/admin/coupon/${code}/redeemed`);
    }

    createSpecialDiscount(discount: SpecialDiscount): Observable<any> {
        return this.http.post(environment.apiUrl + '/api/v1/special_discount', discount);
    }

    updateSpecialDiscount(discount: SpecialDiscount): Observable<any> {
        delete discount.createdAt;
        delete discount.updatedAt;
        delete discount.expiresAtAsInstant;

        return this.http.put(environment.apiUrl + '/api/v1/special_discount', discount);
    }

    getSpecialDiscounts(): Observable<any> {
        return this.http.get(environment.apiUrl + '/api/v1/special_discounts');
    }
}
