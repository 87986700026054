export const FONT_LIST = {
    serif: [
        {label: 'Georgia', value: 'Georgia, serif'},
        {label: 'Palatino Linotype', value: 'Palatino Linotype, Book Antiqua, Palatino, serif'},
        {label: 'Times New Roman', value: 'Times New Roman, Times, serif'},
    ],
    sansSerif: [
        {label: 'Roboto', value: 'Roboto, sans-serif'},
        {label: 'Montserrat', value: 'Montserrat, sans-serif'},
        {label: 'Arial', value: 'Arial, Helvetica, sans-serif'},
        {label: 'Arial Black', value: 'Arial Black, Gadget, sans-serif'},
        {label: 'Impact', value: 'Impact, Charcoal, sans-serif'},
        {label: 'Lucida Sans Unicode', value: 'Lucida Sans Unicode, Lucida Grande, sans-serif'},
        {label: 'Tahoma', value: 'Tahoma, Geneva, sans-serif'},
        {label: 'Trebuchet MS', value: 'Trebuchet MS, Helvetica, sans-serif'},
        {label: 'Verdana', value: 'Verdana, Geneva, sans-serif'}
    ],
    monospace: [
        {label: 'Courier New', value: 'Courier New, Courier, monospace'},
        {label: 'Lucida Console', value: 'Lucida Console, Monaco, monospace'}
    ]
};

export const FONT_GROUPS = [
    {id: 'serif', label: 'Serif'},
    {id: 'sansSerif', label: 'Sans Serif'},
    {id: 'monospace', label: 'Monospace'}
];
